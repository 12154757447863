<template>
  <div class="tw-w-full tw-flex tw-justify-center tw-text-light-1">
    <div class="tw-container-max-width tw-w-full">
      <div
        class="tw-w-full tw-flex tw-justify-between tw-border-b-2 tw-border-dark"
      >
        <div class="tw-flex tw-space-x-2">
          <base-link :to="{ name: MOD_BROWSE_ROUTE }" :class="buttonClasses">
            {{ onModPage ? $t('back_to_browse') : $t('browse') }}
          </base-link>
          <base-link
            class="tw-relative"
            :class="buttonClasses"
            :to="{ name: PROFILE_LIBRARY_ROUTE }"
            @click="goToCollection"
          >
            {{ $t('collection')
            }}<span v-if="isLoggedIn" class="tw-inline-block tw-w-4"
              >&nbsp;</span
            >
            <div
              v-if="isLoggedIn"
              class="tw-absolute tw-top-1.5 tw-right-1 tw-bg-dark-3 tw-text-dark-text tw-rounded-full tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-pointer-events-none"
            >
              {{ collectionCount }}
            </div>
          </base-link>
        </div>
        <div v-if="isLoggedIn" class="tw-flex tw-items-center">
          <button :class="buttonClasses" @click="logout">
            {{ $t('logout') }}
          </button>
        </div>
        <button v-else :class="buttonClasses" @click="triggerModal">
          {{ $t('login') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MOD_BROWSE_ROUTE,
  MOD_VIEW_ROUTE,
  PROFILE_LIBRARY_ROUTE,
} from '@config/routeNames'
import { useAsync, useNavMenus, useRoute, useSearch } from '@composables'
import { authStore, localModStore, subscriptionStore } from '@stores'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_LOGIN } from '@config/globalModals.js'
import { KEYS_MODS } from '@config/storeKeys'
import { logoutRequest } from '@services'
import { computed } from 'vue'

const buttonClasses =
  'tw-px-3 md:tw-px-auto md:tw-w-36 tw-h-9 tw-bg-dark tw-flex tw-items-center tw-justify-center tw-font-bold tw-text-sm tw-text-dark-text hover:tw-bg-primary hover:tw-text-primary-text focus:tw-bg-primary focus:tw-text-primary-text'

export default {
  setup() {
    const { user, isLoggedIn, clearUser } = authStore()
    const { subscriptions } = subscriptionStore()
    const { localMods } = localModStore()
    const { showUserDropdown, showMenu } = useNavMenus()
    const { getGameId, routeName } = useRoute()
    const browseId = `${KEYS_MODS}${getGameId().value}`
    const { setSubmittedBy, searchUserName } = useSearch(browseId)
    const { run } = useAsync(logoutRequest)

    const onModPage = computed(() => routeName.value === MOD_VIEW_ROUTE)

    const collectionCount = computed(
      () => (subscriptions.value?.length || 0) + (localMods.value?.length || 0)
    )

    function triggerModal() {
      setGlobalModal({ modal: MODAL_LOGIN })
    }

    function goToCollection(e) {
      if (!isLoggedIn.value) {
        e.preventDefault()
        triggerModal()
      }
    }

    function logout() {
      run()
      setTimeout(() => {
        clearUser()
        setTimeout(() => window.location.reload(), 200)
      }, 10)
    }

    function filterByUser() {
      setSubmittedBy(user.value.id)
      searchUserName.value = user.value.username
    }

    return {
      PROFILE_LIBRARY_ROUTE,
      MOD_BROWSE_ROUTE,
      showUserDropdown,
      collectionCount,
      goToCollection,
      subscriptions,
      buttonClasses,
      filterByUser,
      triggerModal,
      isLoggedIn,
      onModPage,
      showMenu,
      logout,
      user,
    }
  },
}
</script>
