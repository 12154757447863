export const GAME_ID = 5553
export const PUBLIC_API_URL = 'https://g-5553.modapi.io/v1'
export const PUBLIC_API_KEY = '763652e780febe3c84a1aeded99129af'
export const PRIMARY_COLOR = '#fcaf17'
export const DARK_COLOR = '#000000'
export const LIGHT_COLOR = '#ffffff'
export const DARK_MODE = true
export const TAG_FILTER = null
export const showLegal = false
export const hostURL = '*'
